<template>
  <v-card @click="goToProfile" class="rounded-lg" v-if="agency">
    <div class="h-100 pa-5 d-flex flex-row justify-start align-center">
      <agency-avatar
        :img="agency.midThumbnail ? agency.midThumbnail : agency.image"
        :name="agency.name"
        :size="60"
      />
      <div class="agency-title ml-4 d-flex align-center app-bold-font">
        {{ agency.name }}
      </div>
    </div>
    <v-btn
      block
      style="max-height: 40px; height: 40px;"
      :color="getFanned ? '#A1CC33' : '#472583'"
      dark
      class="rounded-0 rounded-b-lg"
      @click.stop="becameFan"
      :loading="loading"
    >
      {{ getFanned ? "Unfollow" : "Follow" }}
    </v-btn>
  </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
import AgencyAvatar from "@/components/agency/AgencyAvatar.vue";
export default {
  components: { AgencyAvatar },
  props: {
    _id: String
  },
  data() {
    return {
      loading: false,
      agency: null
    };
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      type: "type"
    }),
    getCompletedInfo() {
      var str = "";
      if (!this.agency.image) str += "Logo";
      if (!this.agency.who_we_are)
        str ? (str += ", Who we are") : (str = "Who we are");
      if (!this.agency.what_we_do)
        str ? (str += ", What we do") : (str = "What we do");
      if (!this.agency.contact_person || !this.agency.contact_title)
        str ? (str += ", Contact Info") : (str = "Contact Info");
      if (!this.agency.phone) str ? (str += ", Phone") : (str = "Phone");
      if (!this.agency.address) str ? (str += ", Address") : (str = "Address");
      if (!this.agency.city) str ? (str += ", City") : (str = "City");
      if (!this.agency.state) str ? (str += ", State") : (str = "State");
      if (!this.agency.zip) str ? (str += ", Zip") : (str = "Zip");

      return str;
    },
    getFanned() {
      if (!this.profile.fanned) return false;
      if (this.profile.fanned.includes(this.agency._id)) return true;
      return false;
    }
  },
  methods: {
    ...mapActions({
      followAgency: "volunteer/followAgency",
      getAgency: "agency/getAgency"
    }),
    goToProfile() {
      this.$router.push({
        name: "agency-profile-details",
        params: { id: this.agency._id }
      });
    },
    becameFan() {
      if (this.profile._id == this.agency._id) {
        console.log("You can not follow yourself");
        return;
      }
      this.loading = true;
      var fanned = this.getFanned;
      this.followAgency({ fanned: !fanned, agency: this.agency._id })
        .then(() => {
          this.loading = false;
          // console.log(res);
        })
        .catch(error => {
          this.loading = false;
          console.log(error.response.data.message);
        });
    }
  },
  mounted() {
    this.loading = true;
    this.getAgency(this._id)
      .then(res => {
        this.loading = false;
        this.agency = res;
      })
      .catch(error => {
        this.loading = false;
        console.log(error.response.data.message);
      });
  }
};
</script>
<style>
.agency-title {
  color: #472583;
  font-size: 13px;
  text-align: left;
  text-overflow: ellipsis;
  word-break: break-word;
  line-height: 15px !important;
  max-height: 30px;
  min-height: 30px;
  height: 30px;
  overflow: hidden;
  align-content: center;
}
.agency-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
.rounded-card {
  border-radius: 20px !important;
  /* width: 260px; */
  /* max-width: 260px; */
}
.rounded-card:focus::before {
  border-radius: 20px !important;
}
.bottom-button {
  border-radius: 0 0 20px 20px;
}
</style>
